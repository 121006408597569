import PropTypes from 'prop-types';
import React from 'react';
import WorkCardHome from './work-card-home';

function WorkCardHomeList(props) {
  return (
    <>
      {props.nodes &&
        props.nodes.map((node, index) => <WorkCardHome {...node} isInList key={index} media={props.media} colour={props.colour} />)}
    </>
  );
}

WorkCardHomeList.propTypes = {
  nodes: PropTypes.array,
  media: PropTypes.array,
  colour: PropTypes.string
};

export default WorkCardHomeList;
