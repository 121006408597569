import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Container from '../components/global/container';
import GetInTouch from '../components/global/get-in-touch';
import Header from '../components/global/header';
import SEO from '../components/global/seo';
import GraphQLErrorList from '../components/graphql-error-list';
import InsightSmallCardList from '../components/insights/insight-small-card-list';
import PortableText from '../components/portableText';
import ServicesHome from '../components/services/services-home';
import TeamLargeCardList from '../components/team/team-large-card-list';
import WorkSmallCardList from '../components/work/work-small-card-list';
import WorkCardHomeList from '../components/work/work-card-home-list';
import Layout from '../containers/layout';
import Button from '../components/global/elements/buttons/button';
import {
  buildImageObj,
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes
} from '../lib/helpers';
import { imageUrlFor } from '../lib/image-url';
import Cursor from '../components/cursor';

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      media {
        video {
          asset {
            url
          }
        }
      }
      _rawInsightscopy
      _rawTeamcopy
    }
    static: sanityHomePage(slug: { current: { eq: "home-page" } }) {
      _rawHeading
      buttonLink
      buttonCopy
      seo {
        title
        keywords
        description
        image {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
        }
      }
      media {
        image {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
        }
        video {
          asset {
            url
          }
        }
        alt
      }
      bgColour {
        hex
      }
      _rawIntro
      _rawServices1
      _rawServices2
      _rawServices3
    }
    services: allSanityServices {
      edges {
        node {
          title
          _rawDescription
          bullets
        }
      }
    }
    insights: allSanityInsight(
      limit: 3
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          publishedAt
        }
      }
    }
    workHome: allSanityWork(
      limit: 1, 
      filter: { 
        slug: { 
          current: { eq:"aviva-myworkplace-2021" }
        }
      }
    ) {
      edges {
        node {
            id
            title
            subtitle
            _rawExcerpt
            _rawExcerptStat
            slug {
              current
            }
            cardMedia {
              image {
                crop {
                  _key
                  _type
                  top
                  bottom
                  left
                  right
                }
                hotspot {
                  _key
                  _type
                  x
                  y
                  height
                  width
                }
                asset {
                  _id
                }
              }
              video {
                asset {
                  _id
                }
              }
              alt
            }
          }
      }
    }
    team: allSanityTeam {
      edges {
        node {
          id
          name
          slug {
            current
          }
          image2 {
            alt
            image {
              hotspot {
                _key
                _type
                height
                width
                x
                y
              }
              asset {
                _id
              }
              crop {
                top
                right
                left
                bottom
                _type
                _key
              }
            }
            video {
              asset {
                url
              }
            }
          }
        }
      }
    }
    work: allSanityWork(
      limit: 3,
      filter: { 
        slug: { 
          current: { 
            nin: [ "aviva-myworkplace-2021", "aviva-for-advisers", "aviva-volunteering-campaign", "cancer-enhancements-adviser-awareness-campaign" ]
          }
        }
      }
    ) {
      edges {
        node {
          id
          title
          subtitle
          _rawExcerpt
          _rawExcerptStat
          slug {
            current
          }
          cardMedia {
            image {
              crop {
                _key
                _type
                top
                bottom
                left
                right
              }
              hotspot {
                _key
                _type
                x
                y
                height
                width
              }
              asset {
                _id
              }
            }
            video {
              asset {
                _id
                url
              }
            }
            alt
          }
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const content = (data || {}).static;
  const team = (data || {}).team ? mapEdgesToNodes(data.team) : [];
  const work = (data || {}).work ? mapEdgesToNodes(data.work) : [];
  const workHome = (data || {}).workHome ? mapEdgesToNodes(data.workHome) : [];
  const media = content.media;
  const colour = content.bgColour.hex;
  const insights = (data || {}).insights
    ? mapEdgesToNodes(data.insights)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.35
  });

  const [isShown, setIsShown] = useState(false);

  const handleMouseEnter = e => {
    setIsShown(true);
  }
  const handleMouseLeave = e => {
    setIsShown(false);
  }

  return (
    <>
      <Header headerStyle="clear" />
      <Layout>
        {content.seo && (
          <SEO
            title={content.seo.title !== null ? content.seo.title : false}
            description={content.seo.description !== null ? content.seo.description : false}
            keywords={content.seo.keywords !== null ? content.seo.keywords : false}
            image={content.seo.image !== null ? content.seo.image : false}
          />
        )}
        <Container>
          {/* <Cursor media={media} shown={isShown} /> */}
          <div
            className={`home--banner hero hero--full ${inView ? 'in-view' : ''}`}
            ref={ref}
          >

            <div className="site-wide">
              <div className="home--banner-content">
                <div
                  onMouseOver={handleMouseEnter}
                  onMouseOut={handleMouseLeave}
                >{content._rawHeading && <PortableText blocks={content._rawHeading} />}</div>
                <Button text={content.buttonCopy} link={content.buttonLink} />
              </div>
            </div>

            <GetInTouch bottom />
          </div>

          {workHome && <WorkCardHomeList nodes={workHome} media={media} colour={colour} />}

          {work && <WorkSmallCardList nodes={work} />}
          <ServicesHome content={content} />
          {insights && <InsightSmallCardList nodes={insights} cta copy={site} />}
        </Container>
      </Layout>
    </>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object
};

export default IndexPage;
