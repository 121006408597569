import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import WorkSmallCard from './work-small-card';
import WorkViewAll from './work-view-all';

function WorkSmallCardList(props) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.35
  });

  return (
    <>
      <section className={`section work-cards-small ${inView ? 'in-view' : ''}`} ref={ref}>
        
          {props.nodes &&
            props.nodes.map((node, index) => <WorkSmallCard {...node} isInList key={index} />)}

        <WorkViewAll text="View all case studies" link="/work" />
      </section>
    </>
  );
}

WorkSmallCardList.propTypes = {
  nodes: PropTypes.array
};

export default WorkSmallCardList;
