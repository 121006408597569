import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import React from 'react';
import { getInsightUrl } from '../../lib/helpers';

function InsightSmallCard(props) {
  return (
    <Link to={getInsightUrl(props.slug.current)}>
      <div className="insights-small__date">{format(props.publishedAt, 'MMM D YYYY')}</div>
      <p className="h3-styling">{props.title}</p>

      <div className="btn-container">
        <div className="btn">Read more</div>
      </div>
    </Link>
  );
}

InsightSmallCard.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.object,
  publishedAt: PropTypes.string
};

export default InsightSmallCard;
