import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { buildImageObj, getWorkUrl } from '../../lib/helpers';
import { imageUrlFor } from '../../lib/image-url';
import Button from '../global/elements/buttons/button';
import PortableText from '../portableText';

function WorkCardHome(props) {

  
  useEffect(() => {
    window.addEventListener('scroll', function() {
      const element = document.querySelector('.work-card-home');
      const img = document.querySelector('.home-image img');
      if(element && img) {
        var viewportOffset = element.getBoundingClientRect();
        var viewPoint = viewportOffset.top + 100;
  
        if (viewPoint <= window.innerHeight) {
          img.classList.add('active');
        } else {
          img.classList.remove('active');
        }
      }
    });
  });

  return (
    <div className="work-card work-card-home grid-container grid-container--4-2 grid-container--reverse">        
        <div className="grid-column copy">
          <div className="work-card__details">
              <h2>{props.title}</h2>
              <PortableText blocks={props._rawExcerpt} />
              {props._rawExcerptStat && (
                <PortableText blocks={props._rawExcerptStat} />
              )}
              <Button text="View full case study" link={getWorkUrl(props.slug.current)} />
          </div>
        </div>

        <div className="grid-column image">
            <div className="work-card__image home-image" style={{'backgroundColor': props.colour}}>
              {props.media[0].image && props.media[0].image.asset && (
                <img
                  src={imageUrlFor(buildImageObj(props.media[0].image)).auto('format').url()}
                  alt={props.media[0].alt}
                />
              )}
            </div>
        </div>
    </div>
  );
}

WorkCardHome.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  rawIntro: PropTypes.string,
  slug: PropTypes.object,
  media: PropTypes.array,
  colour: PropTypes.string
};

export default WorkCardHome;
