import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import Button from '../global/elements/buttons/button';
import InsightSmallCard from './insight-small-card';
import PortableText from '../../components/portableText';

function InsightSmallCardList(props) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.35
  });

  return (
    <section
      ref={ref}
      className={`section ${
        props.overlapped ? 'section--overlapped' : ''
      } insights-small bg-grey-light ${inView ? 'in-view' : ''}`}
    >
      <div className="site-wide grid-container grid-container--4-2 grid-container--reverse">
        <div className="grid-column insights-small__title">
          <h2>Insights</h2>
          {props.copy._rawInsightscopy && <PortableText blocks={props.copy._rawInsightscopy} />}
          {props.cta ? <Button link="/insights" text="View all our latest news stories" /> : ''}
        </div>

        <div className="grid-column">
          {props.nodes &&
            props.nodes.map((node, index) => (
              <div className="insights-small__item" key={index}>
                <InsightSmallCard {...node} isInList />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}

InsightSmallCardList.propTypes = {
  nodes: PropTypes.array,
  overlapped: PropTypes.bool,
  cta: PropTypes.bool,
  copy: PropTypes.object
};

export default InsightSmallCardList;
