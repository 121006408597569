import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { buildImageObj, getTeamUrl } from '../../lib/helpers';
import { imageUrlFor } from '../../lib/image-url';

function TeamLargeCard(props) {
  return (
    <Link to={getTeamUrl(props.slug.current)}>
      <div>
        {props.image2.image && props.image2.image.asset && (
          <img
            src={imageUrlFor(buildImageObj(props.image2.image))
              .url()}
            alt={props.image2.alt}
          />
        )}
      </div>
      <p className="h3-styling">{props.name}</p>
    </Link>
  );
}

TeamLargeCard.propTypes = {
  name: PropTypes.string,
  slug: PropTypes.object,
  image2: PropTypes.object
};

export default TeamLargeCard;
