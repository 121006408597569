import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { buildImageObj, getWorkUrl } from '../../lib/helpers';
import { imageUrlFor } from '../../lib/image-url';
import Button from '../global/elements/buttons/button';
import PortableText from '../portableText';

function WorkSmallCard(props) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.35
  });
  return (
    <div className={`work-card grid-container grid-container--4-2 site-wide grid-container--reverse ${inView ? 'in-view' : ''}`} ref={ref}>        
        <div className="grid-column copy">
          <div className="work-card__details">
            
              <h2>{props.title}</h2>
              <PortableText blocks={props._rawExcerpt} />
              <PortableText blocks={props._rawExcerptStat} />
              <Button text="View full case study" link={getWorkUrl(props.slug.current)} />
          </div>
        </div>

        <div className="grid-column image">
          <Link to={getWorkUrl(props.slug.current)}>
            <div className="work-card__image">
              {props.cardMedia.image && props.cardMedia.image.asset && (
                <img
                  src={imageUrlFor(buildImageObj(props.cardMedia.image)).auto('format').url()}
                  alt={props.cardMedia.alt}
                />
              )}

              {props.cardMedia.video && props.cardMedia.video.asset && (
                <video className="hero__media" autoPlay loop muted playsInline>
                  <source src={props.cardMedia.video.asset.url} type="video/mp4" />
                </video>
              )}
            </div>
          </Link>
        </div>
    </div>
  );
}

WorkSmallCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  rawIntro: PropTypes.string,
  slug: PropTypes.object,
  cardMedia: PropTypes.object
};

export default WorkSmallCard;
