import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import Button from '../global/elements/buttons/button';
import TeamLargeCard from './team-large-card';
import PortableText from '../../components/portableText';

function shuffleArray(array) {
  let i = array.length - 1;
  const location = useLocation();
  const slug = location.pathname.replace(/\/team\//g, '');

  for (; i > 0; i--) {
    if (array[i].slug.current === slug) {
      array.splice(i, 1);
    }
  }

  array.sort(() => Math.random() - 0.5);

  return array;
}

function TeamLargeCardList(props) {
  const shuffledPosts = shuffleArray(props.nodes);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.35
  });

  return (
    <section
      className={`section q-a ${inView ? 'in-view' : ''}`}
      ref={ref}
      style={{ backgroundColor: props.bgColour }}
    >
      <div className="site-wide grid-container grid-container--4-2 grid-container--reverse">
        <div className="grid-column q-a__title">
          <h2>Our team</h2>
          {props.copy._rawTeamcopy && <PortableText blocks={props.copy._rawTeamcopy} />}
          {props.cta ? <Button link="/team" text="Meet the ewe team" /> : ''}
        </div>

        <div className="grid-column q-a__items">
          {shuffledPosts.slice(0, 2).map((node, index) => (
            <div className="q-a__item" key={index}>
              <TeamLargeCard {...node} isInList />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

TeamLargeCardList.propTypes = {
  nodes: PropTypes.array,
  cta: PropTypes.bool,
  bgColour: PropTypes.string,
  copy: PropTypes.object
};

export default TeamLargeCardList;
