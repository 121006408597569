import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import Button from '../global/elements/buttons/button';

function WorkViewAll(props) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.25
  });

  return (
    <>
      <div className={`view-all ${inView ? 'in-view' : ''}`} ref={ref}>
        <Button text={props.text} link={props.link} />
      </div>
    </>
  );
}

WorkViewAll.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string
};

export default WorkViewAll;
